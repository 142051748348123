import Vue from 'vue';

import Vuetify from 'vuetify/lib/framework';

import AddressIcon from './icons/address-icon.vue';
import UsDollarIcon from './icons/us-dollar-icon.vue';
import SettingsIcon from './icons/settings-icon.vue';
import HomeIcon from './icons/home-icon.vue';
import SupportIcon from './icons/support-icon.vue';
import PaymentIcon from './icons/payment-icon.vue';
import FooterPaymentIcon from './icons/footer-payment-icon.vue';
import TriangleDownIcon from './icons/triangle-down-icon.vue';
import AccountIcon from './icons/account-icon.vue';
import NotificationIcon from './icons/notification-icon.vue';
import TwoStepVerificationIcon from './icons/two-step-verification-icon.vue';
import ChangePasswordIcon from './icons/change-password-icon.vue';
import LanguageIcon from './icons/language-icon.vue';
import DuplicateIcon from './icons/duplicate-icon.vue';
import CardIcon from './icons/card-icon.vue';
import OrdersIcon from './icons/orders-icon.vue';
import InvoicesIcon from './icons/invoices-icon.vue';
import TransactionHistoryIcon from './icons/transaction-history-icon.vue';
import CallIcon from './icons/call-icon.vue';
import ChatIcon from './icons/chat-icon.vue';
import ArrowRightIcon from './icons/arrow-right-icon.vue';
import ArrowLeftIcon from './icons/arrow-left-icon.vue';
import RemoveNotificationIcon from './icons/remove-notification-icon.vue';
import USFlagIcon from './icons/us-flag-icon.vue';
import HeartIcon from './icons/heart-icon.vue';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      'address-icon': {
        component: AddressIcon
      },
      'us-dollar-icon': {
        component: UsDollarIcon
      },
      'settings-icon': {
        component: SettingsIcon
      },
      'home-icon': {
        component: HomeIcon
      },
      'support-icon': {
        component: SupportIcon
      },
      'payment-icon': {
        component: PaymentIcon
      },
      'footer-payment-icon': {
        component: FooterPaymentIcon
      },
      'triangle-down-icon': {
        component: TriangleDownIcon
      },
      'account-icon': {
        component: AccountIcon
      },
      'notification-icon': {
        component: NotificationIcon
      },
      'two-step-verification-icon': {
        component: TwoStepVerificationIcon
      },
      'change-password-icon': {
        component: ChangePasswordIcon
      },
      'language-icon': {
        component: LanguageIcon
      },
      'duplicate-icon': {
        component: DuplicateIcon
      },
      'card-icon': {
        component: CardIcon
      },
      'orders-icon': {
        component: OrdersIcon
      },
      'invoices-icon': {
        component: InvoicesIcon
      },
      'transaction-history-icon': {
        component: TransactionHistoryIcon
      },
      'call-icon': {
        component: CallIcon
      },
      'chat-icon': {
        component: ChatIcon
      },
      'arrow-right-icon': {
        component: ArrowRightIcon
      },
      'arrow-left-icon': {
        component: ArrowLeftIcon
      },
      'remove-notification-icon': {
        component: RemoveNotificationIcon
      },
      'us-flag-icon': {
        component: USFlagIcon
      },
      'heart-icon': {
        component: HeartIcon
      }
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#F9AC40',
        // primary: '#000000',
        // accent: '#fcb72c',
        secondary: '#231F20'
      }
    }
  }
});
