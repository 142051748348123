import Vue from 'vue';
// import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';

const VeeValidateConfig = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  dictionary: null,
  errorBagName: 'errs', // change if property conflicts
  events: 'input|blur',
  fieldsBagName: 'fields',
  i18n: null, // the vue-i18n plugin instance
  i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  inject: true,
  locale: 'en',
  strict: true,
  validity: false
};

VeeValidate.Validator.extend('atLeastOne', {
  getMessage() {
    return `At least one item must be selected.`;
  },
  validate: () => {
    return this.value.some((list) => list == true);
  }
});

VeeValidate.Validator.extend('verify_password', {
  getMessage: () =>
    `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character`,
  validate: (value) => {
    var strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&()+,-:;<>_~|])(?=.{8,})'
    );
    return strongRegex.test(value);
  }
});

Vue.use(VeeValidate, VeeValidateConfig);
