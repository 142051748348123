<template>
  <div>
    <!-- <transition
      name="component-fade"
      mode="out-in"
    > -->
    <!-- <div v-show="$auth.ready()"> -->
    <!-- <transition name="slide-y-transition">
          <transition
            name="component-fade"
            mode="out-in"
          > -->
    <component :is="layout">
      <transition
        name="component-fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>
    <!-- </transition>
        </transition> -->
    <notifications
      group="main"
      :position="$vuetify.breakpoint.mdAndUp ? 'top center' : 'top center'"
      :classes="
        $store.getters['main/is_pudo']
          ? 'pudo-notification'
          : 'stallion-notification'
      "
      animation-type="velocity"
      :ignore-duplicates="true"
    />
    <v-dialog
      v-model="dialog"
      persistent
      width="300"
    >
      <v-card dark>
        <v-card-text style="margin: 20px">
          {{ message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- </div> -->
    <!-- </transition> -->

    <!-- <transition
      name="component-fade"
      mode="out-in"
    >
      <div
        v-show="!$auth.ready()"
        class="loading-overlay"
      >
        <div class="content">
          <atom-spinner
            style="margin: auto"
            :animation-duration="1000"
            :size="100"
            :color="'#f9ac40'"
          />
          <div>
            <h2>{{ fun_message }}</h2>
          </div>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
const default_layout = 'auth-layout';
import { AtomSpinner } from 'epic-spinners';
import Funnies from 'funnies';
import Vue from 'vue';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';

export default {
  metaInfo: {
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} | Shippsy` : 'Shippsy';
    }
  },
  components: {
    AtomSpinner
  },
  props: [],
  data() {
    return {
      model: {},
      fun_message: ''
    };
  },
  computed: {
    layout() {
      return this.$route.meta.layout || default_layout;
    },
    dialog() {
      return this.$store.state.main.loading_dialog;
    },
    message() {
      return this.$store.state.main.loading_message;
    }
  },
  watch: {
    '$auth.authenticated': {
      immediate: true,

      handler() {
        if (this.$auth.authenticated === true) {
          this.getReturnCounts();
          // initial call for notification summary
          this.getNotifications();
          var words = this.$auth.user.name.split(' ');

          var first_letter = words[0].charAt(0);
          var second_letter = '';

          if (words[1]) second_letter = words[1].charAt(0);

          this.$store.commit('main/setInitials', first_letter + second_letter);

          // window.Echo.connect();

          // window.Echo.connector.pusher.config.auth.headers[
          //   'Authorization'
          // ] = `Bearer ${this.$auth.token()}`;

          // window.Echo.private(`users.${this.$auth.user.id}`).notification(
          //   (notification) => {
          //     console.log(notification);
          //     this.$notify({
          //       group: 'main',
          //       title: notification.title,
          //       text: notification.message,
          //       duration: 5000
          //     });

          //     notification.created_at = window.moment().unix();
          //     notification.read_at = null;

          //     this.$store.commit('main/addNotification', notification);

          //     this.$store.state.main.unread++;
          //   }
          // );

          try {
            Beacon('identify', {
              name: Vue.auth.user.name,
              email: Vue.auth.user.email,
              company: Vue.auth.user.business_name,
              site: 'portal.shippsy.com'
            });

            // window.Tooltip.API.updateUserData({
            //   name: window.Vue.auth.user.name,
            //   email: window.Vue.auth.user.email
            // });
          } catch (err) {
            console.log(err);
          }
        } else {
          try {
            window.Beacon('logout', { endActiveChat: true });
            // window.Tooltip.API.updateUserData({});

            // window.Echo.disconnect();
          } catch (err) {
            console.log(err);
          }
        }
      },

      deep: true
    }
  },
  mounted() {
    let funnies = new Funnies();

    this.fun_message = funnies.message();

    // if mobile
    if (this.$vuetify.breakpoint.mdAndDown) {
      window.Beacon('config', {
        hideFABOnMobile: true,
        display: {
          verticalOffset: 90,
          horizontalOffset: 20
        }
      });
    }

    Event.listen('update-shipments', () => {
      this.getReturnCounts();
    });

    this.$nextTick(() => {
      if (window.is_pudo) {
        this.$store.commit('main/setIsPudo', true);
      } else {
        this.$store.commit('main/setIsPudo', false);
      }
    });
  },
  created() {
    this.initializePushNotification();
  },
  methods: {
    initializePushNotification() {
      if (!Capacitor.isPluginAvailable('PushNotifications')) {
        return;
      }

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token) => {
        console.log('Push registration success, token: ' + token.value);
        // will be used to update fcm token upon login @login.vue
        this.$store.commit('main/setFCMToken', token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          console.log('Push action performed: ' + notification);
          try {
            // alert(
            //   JSON.stringify({
            //     url: notification.notification?.data?.action_url,
            //     www: new URL(notification.notification?.data?.action_url)
            //   })
            // );
            if (notification.notification?.data?.action_url) {
              const url = new URL(notification.notification?.data?.action_url);
              this.$router.push(url.pathname + url.search);
            }
          } catch (ex) {
            // alert('ERROR: ' + JSON.stringify(ex));
          }
        }
      );
    },
    getNotifications() {
      this.$http
        .get(`/notifications`)
        .then((response) => response.data)
        .then((response) => {
          // console.log(response);
          this.$store.dispatch('main/setNotifications', {
            notifications: response.latestSummary || [],
            unread: response.unread
          });
        });
    },
    getReturnCounts: function () {
      this.$http.get('/shipments/counts').then((res) => {
        this.$store.state.main.counts = res.data.counts;
      });
    }
  }
};
</script>

<style></style>
