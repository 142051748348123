import Vuetify from '../../plugins/vuetify';

const settings = {
  shippsy: {
    title: 'Shippsy',
    url: {
      support: 'https://help.shippsy.com/article/250-how-to-use-shippsy'
    },
    backgroundColor: 'rgb(255, 247, 226)',
    authLogo: 'images/logo.png',
    authLogo3: 'images/logo.png',
    loginImage: 'images/hero.png',
    slogan: 'Cross border shopping made simple.',
    registerSlogan: 'Ship to our US address. Pick up in the GTA.',
    registerImage: 'images/sign-up-page.png',
    navbarLogo: 'images/logo.png', // edit
    noShipmentsImage: 'images/no-shipments-02.png',
    supportSchedule:
      'Monday to Friday - 8AM - 6PM EST<br>Saturday - Closed<br> Sunday - 12PM - 3PM EST',
    phone: '1-888-744-7779',
    email: 'hello@shippsy.com',
    alertClass: 'shippsy-alert',
    mobileLogo: 'images/shippsy-icon.png',
    color: {
      primary: '#f9ab40',
      clickableLink: '#1f00dc;',
      'Action Required': 'rgba(255,72,72,0.3)',
      'Pre-Transit': 'rgba(255,207,115,0.5)',
      'In Transit': 'rgba(151,240,149,0.7)',
      'Shippsy Approval': 'rgba(249,172,64,0.55)',
      'Ready for Pickup': 'rgba(175,72,255,0.3)',
      Complete: 'rgba(150,179,255,0.6)',
      Orders: {
        Ready: 'rgba(175,72,255,0.3)',
        'In Transit': 'rgba(151,240,149,0.7)',
        Complete: 'rgba(150,179,255,0.6)',
        Exception: 'rgba(255,72,72,0.3)'
      }
    },

    // headerMenu1: [
    //   // {
    //   //   icon: 'mdi-view-dashboard',
    //   //   to: '/dashboard',
    //   //   text: 'Dashboard',
    //   //   type: 'link'
    //   // },
    //   // {
    //   //   icon: 'mdi-credit-card',
    //   //   to: '/payments',
    //   //   text: 'Payments',
    //   //   type: 'link'
    //   // },
    //   // {
    //   //   icon: 'mdi-file-document',
    //   //   to: '/invoices',
    //   //   text: 'Invoices',
    //   //   type: 'link'
    //   // },
    //   // {
    //   //   icon: 'mdi-comment-question-outline',
    //   //   text: 'Help',
    //   //   to: 'openHelpscout',
    //   //   type: 'button'
    //   // },
    //   // {
    //   //   icon: 'mdi-cogs',
    //   //   to: '/settings',
    //   //   text: 'Settings',
    //   //   type: 'link',
    //   //   divider: true
    //   // },
    //   {
    //     icon: 'mdi-home',
    //     to: '/',
    //     text: 'Home',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-card-bulleted',
    //     to: '/us-address',
    //     text: 'Address',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-headphones',
    //     to: '/support',
    //     text: 'Support',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-currency-usd',
    //     to: '/payments',
    //     text: 'Payments',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-settings',
    //     to: '/settings',
    //     text: 'Settings',
    //     type: 'link',
    //     divider: true
    //   },
    //   {
    //     icon: 'mdi-lock-open',
    //     to: '/admin',
    //     text: 'Admin Panel',
    //     permission: 'admin.access',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-logout',
    //     to: 'logout',
    //     text: 'Logout',
    //     type: 'button'
    //   }
    // ],

    footerMobileMenu: [
      {
        icon: '$home-icon',
        to: '/dashboard',
        text: 'Home',
        type: 'link'
      },
      {
        icon: '$address-icon',
        to: '/us-address',
        text: 'Address',
        type: 'link'
      },
      {
        icon: 'mdi-lock-open',
        to: '/admin',
        text: 'Admin',
        type: 'link',
        permission: 'admin.access'
      },
      {
        icon: '$support-icon',
        to: '/support',
        text: 'Support',
        type: 'link'
      },
      {
        icon: '$footer-payment-icon',
        to: '/payments',
        text: 'Payments',
        type: 'link'
      },
      {
        icon: '$settings-icon',
        to: '/settings',
        text: 'Settings',
        type: 'link'
      }
    ]
  },
  pudo: {
    title: 'PUDO',
    backgroundColor: '#c1e1f0',
    authLogo: 'images/pudo/pudo_shippsy.png',
    url: {
      support:
        'https://www.pudopoint.com/pudo-point-counters/contact-us/#:~:text=CUSTOMER%20SERVICE,service%20and%20speedy%20response%20times'
    },
    slogan:
      'Worldwide shipping & receiving as close as your local corner store',
    registerSlogan: "Convenience without borders.  It's about time!",
    registerImage: null,
    navbarLogo: 'images/pudo/pudo_shippsy_scalled.png',
    noShipmentsImage: 'images/no-shipments-02.png',
    supportSchedule: 'Monday to Friday 8AM - 5PM EST',
    phone: '1-844-300-8533',
    email: 'support@pudo.freshdesk.com',
    alertClass: 'pudo-alert',
    mobileLogo: 'images/pudo/pudo_mobile.PNG',
    color: {
      primary: '#44A3D1',
      clickableLink: '#44A3D1;',
      'Action Required': 'rgba(255,72,72,0.3)',
      'Pre-Transit': 'rgba(255,207,115,0.5)',
      'In Transit': 'rgba(151,240,149,0.7)',
      'Shippsy Approval': 'rgba(249,172,64,0.55)',
      'Ready for Pickup': 'rgba(175,72,255,0.3)',
      Complete: 'rgba(150,179,255,0.6)',
      Orders: {
        Ready: 'rgba(175,72,255,0.3)',
        'In Transit': 'rgba(151,240,149,0.7)',
        Complete: 'rgba(150,179,255,0.6)',
        Exception: 'rgba(255,72,72,0.3)'
      }
    },

    // headerMenu1: [
    //   {
    //     icon: 'mdi-view-dashboard',
    //     to: '/dashboard',
    //     text: 'Dashboard',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-credit-card',
    //     to: '/payments',
    //     text: 'Payments',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-file-document',
    //     to: '/invoices',
    //     text: 'Invoices',
    //     type: 'link'
    //   },
    //   // {
    //   //   icon: 'mdi-comment-question-outline',
    //   //   text: 'Help',
    //   //   to: 'openHelpscout',
    //   //   type: 'button'
    //   // },
    //   {
    //     icon: 'mdi-cogs',
    //     to: '/settings',
    //     text: 'Settings',
    //     type: 'link',
    //     divider: true
    //   },
    //   {
    //     icon: 'mdi-lock-open',
    //     to: '/admin',
    //     text: 'Admin Panel',
    //     permission: 'admin.access',
    //     type: 'link'
    //   },
    //   {
    //     icon: 'mdi-logout',
    //     to: 'logout',
    //     text: 'Logout',
    //     type: 'button'
    //   }
    // ]

    footerMobileMenu: [
      {
        icon: '$home-icon',
        to: '/dashboard',
        text: 'Home',
        type: 'link'
      },
      {
        icon: '$address-icon',
        to: '/us-address',
        text: 'Address',
        type: 'link'
      },
      {
        icon: '$support-icon',
        to: '/support',
        text: 'Support',
        type: 'link'
      },
      {
        icon: '$footer-payment-icon',
        to: '/payments',
        text: 'Payments',
        type: 'link'
      },
      {
        icon: '$settings-icon',
        to: '/settings',
        text: 'Settings',
        type: 'link'
      }
    ]
  }
};

function initialState() {
  return {
    notifications: [],
    unread: 0,
    initials: 'SE',
    selectedShipments: [],
    selectedBatches: [],

    print_dialog: false,
    cart: [],
    loading_dialog: false,
    loading_message: '',
    settings: settings['shippsy'],
    counts: {
      shipments: []
    },
    is_pudo: false,
    fcm_token: null
  };
}

const themes = {
  shippsy: {
    dark: {
      primary: '#21CFF3'
      // accent: '#FF4081',
      // secondary: '#21dc79',
      // success: '#86af3f',
      // info: '#f34fc6',
      // warning: '#FB8C00',
      // error: '#FF5252'
    },
    light: {
      primary: '#F9AC40',
      // accent: '#231F20',
      secondary: '#231F20'
      // success: '#a5d64c',
      // info: '#ff53d0',
      // warning: '#ff8e00',
      // error: '#ff5252'
    }
  },
  pudo: {
    name: 'pudo',
    dark: {
      orange: '#E65100',
      primary: '#E65100'
      // accent: '#7CB342',
      // secondary: '#689F38',
      // success: '#4CAF50',
      // info: '#6156d8',
      // warning: '#1565C0',
      // error: '#FF7043'
    },
    light: {
      orange: '#44A3D1',
      primary: '#44A3D1'
      // accent: '#a1e754',
      // secondary: '#92de4e',
      // success: '#6dff74',
      // info: '#7365ff',
      // warning: '#2e8ac0',
      // error: '#ff5e3c'
    }
  }
};

const main = {
  namespaced: true,
  state: initialState(),
  actions: {
    openDialog({ state, commit }, message) {
      console.log(state);

      commit('updateMessage', message);
      commit('setDialog', true);
    },
    closeDialog({ commit }) {
      console.log('message');
      commit('setDialog', false);
      // commit('close');
    },
    setNotifications(context, payload) {
      var formattedNotification = [];
      (payload.notifications || []).forEach((notification) => {
        var data = notification.data; // already parsed as json_decode from backend
        formattedNotification.push({
          id: notification.id,
          title: data.title,
          message: data.message,
          created_at: notification.created_at,
          read_at: notification.read_at
        });
      });
      // initial/main notifications and will be displayed in the dropdown notification header
      context.commit('setNotifications', formattedNotification);
      context.commit('setUnread', payload.unread);
    },
    setTheme({ state }, name) {
      const theme = themes[name];

      const dark = theme.dark;
      const light = theme.light;
      // set themes
      Object.keys(dark).forEach((i) => {
        Vuetify.framework.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach((i) => {
        Vuetify.framework.theme.themes.light[i] = light[i];
      });
      // also save theme name to disable selection
      Vuetify.framework.theme.themes.name = name;

      state.settings = settings[name];
    }
  },
  mutations: {
    setFCMToken(state, token) {
      state.fcm_token = token;
    },
    setIsPudo(state, value) {
      state.is_pudo = value;

      if (value) {
        this.dispatch('main/setTheme', 'pudo');
      } else {
        this.dispatch('main/setTheme', 'shippsy');
      }
    },
    updateMessage(state, message) {
      state.loading_message = message;
    },
    setDialog(state, bool) {
      state.loading_dialog = bool;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setSelectedShipments(state, shipments) {
      state.selectedShipments = shipments;
    },
    setSelectedBatches(state, batches) {
      state.selectedBatches = batches;
    },

    addNotification(state, notification) {
      state.notifications.unshift(notification);
    },

    setInitials(state, value) {
      state.initials = value;
    },
    setUnread(state, value) {
      state.unread = value;
    },
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    }
  },
  getters: {
    notifications: (state) => state.notifications,
    initials: (state) => state.initials,
    unread: (state) => state.unread,
    is_pudo: (state) => state.is_pudo,
    fcm_token: (state) => state.fcm_token
  }
};

export default main;
