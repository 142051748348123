import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import router from '../router';

Sentry.init({
    Vue,
    dsn: import.meta.env.PROD ? 'https://cd8dc4db72964278810baf513d771f2c@o4505326130626560.ingest.sentry.io/4505353150857216' : null,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/.*\.shippsy\.com\/api/, /^https:\/\/.*\.pudopoint\.com\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });