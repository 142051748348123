import Vue from 'vue';

Vue.component('AuthLayout', () =>
  import( /* webpackChunkName: "auth" */ '../components/layouts/AuthLayout.vue')
);


Vue.component('SingleCardLayout', () =>
  import( /* webpackChunkName: "auth" */ '../components/layouts/SingleCardLayout.vue')
);

