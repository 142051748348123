import Vue from 'vue';

Vue.component('HorizontalbarChart', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/charts/horizontal-bar.vue'
  )
);
Vue.component('PieChart', () =>
  import(/* webpackChunkName: "shared" */ '../components/shared/charts/pie.vue')
);
Vue.component('LineChart', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/charts/line.vue'
  )
);

Vue.component('BarChart', () =>
  import(/* webpackChunkName: "shared" */ '../components/shared/charts/bar.vue')
);

Vue.component('FullPageLayout', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/layouts/FullPageLayout.vue'
  )
);
Vue.component('SidePanelLayout', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/layouts/SidePanelLayout.vue'
  )
);
Vue.component('ShippsyDialog', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/ShippsyDialog.vue'
  )
);

Vue.component('TransactionTable', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/transactions/TransactionsTable.vue'
  )
);

Vue.component('VerifyPhone', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/VerifyPhone.vue'
  )
);

Vue.component('PudoLocationSelector', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/PudoLocationSelector.vue'
  )
);

Vue.component('DatePicker', () =>
  import(
    /* webpackChunkName: "main" */ '../components/shared/inputs/date-picker.vue'
  )
);

Vue.component('WeglotSwitcher', () =>
  import(
    /* webpackChunkName: "main" */ '../components/shared/WeglotSwitcher.vue'
  )
);

Vue.component('ShippsyLoader', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/ShippsyLoader.vue'
  )
);

Vue.component('ManageColumnDialog', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/ManageColumnDialog.vue'
  )
);

Vue.component('ShippsyTableSettings', () =>
  import(
    /* webpackChunkName: "shared" */ '../components/shared/ShippsyTableSettings.vue'
  )
);
