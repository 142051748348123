import printJS from 'print-js';
import swal from 'sweetalert';
window.printJS = printJS;
import Vapor from 'laravel-vapor';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import _find from 'lodash/find';
import _differenceBy from 'lodash/differenceBy';
import TABLE_COLUMNS from '@/constants/table-column';
import axios from 'axios';
// import { Browser } from '@capacitor/browser';
const url = import.meta.env.BASE_URL;
import { FileOpener } from '@capacitor-community/file-opener';
if (url !== '/') {
  const newUrl = url.replace('/build/', '');
  Vapor.withBaseAssetUrl(newUrl);
} else {
  Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
}

window.Vapor = Vapor;

export default {
  methods: {
    async store(file, options = {}) {
      const response = await this.$http.post('/vapor/signed-storage-url', {
        bucket: options.bucket || '',
        content_type: options.contentType || file.type,
        expires: options.expires || ''
      });

      if (typeof options.progress === 'undefined') {
        options.progress = () => {};
      }

      var instance = this.$http.create();
      instance.defaults.headers.common = {};
      await instance.put(response.data.url, file, {
        headers: response.data.headers,
        onUploadProgress: (progressEvent) => {
          options.progress(progressEvent.loaded / progressEvent.total);
        }
      });

      response.data.extension = file.name.split('.').pop();

      return response.data;
    },
    async openDocument(path) {
      // get link from backend by posting path

      const response = await this.$http.post('/admin/shipments/document-link', {
        path: path
      });

      const url = response.data.url;

      if (Capacitor.isNativePlatform()) {
        const openUrl = Capacitor.convertFileSrc(url);
        FileOpener.open({
          filePath: openUrl,
          contentType: 'application/pdf'
        });
      } else {
        window.open(url, '_blank');
      }
    },
    async capacitorDownload(url, fileName, type = 'application/pdf') {
      try {
        var instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];

        const response = await instance.get(url, {
          responseType: 'arraybuffer'
        });

        if (Capacitor.isNativePlatform()) {
          const directory = Directory.Data;

          const base64Data = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ''
            )
          );

          const result = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: directory,
            recursive: true
          });

          if (result && result.uri) {
            // alert('File saved:' + result.uri);
            // const openUrl = Capacitor.convertFileSrc(result.uri);
            await FileOpener.open({
              filePath: result.uri,
              contentType: type
            });
          } else {
            alert('Failed to save file.');
          }
        } else {
          const blob = new Blob([response.data], { type: type });
          // Fallback method for downloading in a web browser
          const downloadUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error('Download failed:', error);
      }
    },
    requestable(shipments) {
      if (
        shipments.length >= 1 &&
        shipments.every((r) => [10, 5].includes(r.status_id)) &&
        shipments.every((r) => r.service_id === 1)
      ) {
        return true;
      } else {
        return false;
      }
    },
    payable(shipments) {
      if (
        shipments.length != 0 &&
        shipments.every((r) => r.status_id === 30) &&
        shipments.every(
          (r) => r.current_location_id === r.destination_location_id
        ) &&
        shipments.every((r) => r.service_id === 2) &&
        shipments.every((r) => r.order_id === null) &&
        shipments.every((r) => r.duty_corrected_at !== null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    disposable(shipments) {
      if (shipments.length != 0 && shipments.every((r) => r.service_id === 1)) {
        return true;
      } else {
        return false;
      }
    },

    deletable(shipments) {
      if (shipments.length != 0 && shipments.every((r) => r.status_id === 35)) {
        return true;
      } else {
        return false;
      }
    },
    can_provide_details(item) {
      return item.status_id === 1 && item.service_id !== 3;
    },
    printLabel(id) {
      this.$http
        .get(`/admin/shipments/${id}/label`, {
          responseType: 'arraybuffer'
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/pdf'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);

          printJS(link.href);
        });
    },
    toggleBeacon: function () {
      window.Beacon('toggle');
    },
    async confirmPayment(value) {
      if (!this.$auth.authenticated) {
        console.log('Not logged in');
        return false;
      }

      const amount = Number(value.toFixed(2));
      const userCredits = Number(this.$auth.user.credits);
      const toPay = userCredits < amount ? amount - userCredits : amount;

      const isConfirm = await swal({
        className: this.$store.state.main.settings.alertClass,
        title: 'Confirm Payment',
        text: `You are about to pay ${this.$options.filters.currency(
          toPay
        )}.\n Do you wish to proceed with payment?`,
        // icon: 'info',
        buttons: ['Cancel', 'Confirm']
      });

      return isConfirm;
    },
    hasRole: function (role) {
      if (!this.$auth.authenticated) return false;

      if (this.$auth.user.roles.find((obj) => obj.name == role)) return true;
      else return false;
    },

    hasPermission: function (permission) {
      if (!this.$auth.authenticated) return false;

      if (this.$auth.user.roles.find((obj) => obj.name == 'super-admin'))
        return true;
      else if (
        this.$auth
          .user()
          .roles.find((obj) =>
            obj.permissions.find((obj) => obj.name == permission)
          )
      )
        return true;
      else if (
        this.$auth.user.permissions.find((obj) => obj.name == permission)
      )
        return true;
      else return false;
    },

    can: function (permission) {
      if (!this.$auth.authenticated) return false;

      if (this.$auth.user.can && this.$auth.user.can[permission] === true)
        return true;
      else return false;
    },
    getOverCharge: function (weight, limit) {
      let price = 0;
      for (let x = limit; x < weight; x++) {
        price += 0.25;
      }
      return Number.parseFloat(price).toFixed(2);
    },
    errorMessage: function (
      message = 'An unknown error occurred',
      title = 'Error'
    ) {
      swal({
        className: this.$store.state.main.settings.alertClass,
        title: title,
        text: message,
        icon: 'error'
      });
    },
    successMessage: function (message, title = 'Success') {
      swal({
        className: this.$store.state.main.settings.alertClass,
        title: title,
        text: message,
        // icon: 'success',
        button: 'Got it!',
        icon: 'success'
      });
    },
    getDescription: function (item, prohibited = []) {
      let str = this.getItemText(item.description, prohibited);
      if (item.value) {
        str += `<span class="float-right">${
          item.quantity
        } x ${this.$options.filters.currency(item.value)}</span>`;
      }
      return str;
    },
    getItemText(text, prohibited = []) {
      prohibited = prohibited.map((x) =>
        x.description.replace(/\s/g, '').toLowerCase()
      );
      let string = '';
      let temp = text?.replace(/\s/g, '').toLowerCase();

      if (temp) {
        for (let prohibited_item of prohibited) {
          if (
            (prohibited_item.includes(temp) &&
              prohibited_item.length == temp.length) ||
            temp.includes(prohibited_item)
          ) {
            string = `<span style="color: red">${text} </span>`;
            break;
          }
        }
        if (string == '') {
          string = `<span>${text} </span>`;
        }

        return string;
      } else {
        return text;
      }
    },
    getVendorAddress: function (shipment) {
      if (shipment.vendor) {
        return `${shipment.vendor.address}, ${shipment.vendor.city}, \n
                ${shipment.vendor.province_code}, ${shipment.vendor.country_code}, ${shipment.vendor.postal_code}`;
      } else if (shipment.vendor_address1) {
        return `${shipment.vendor_address1}, ${shipment.vendor_city}, \n
                ${shipment.vendor_province_code}, ${shipment.vendor_country_code}, ${shipment.vendor_postal_code}`;
      }
      return '';
    },
    shipmentStatusColor: function (shipment) {
      // TODO: update correct status ids
      switch (shipment.status) {
        case 'action-required':
          return this.$store.state.main.settings.color.actionRequired;
        case 'pre-transit':
          return this.$store.state.main.settings.color.preTransit;
        case 'in-transit':
          return this.$store.state.main.settings.color.inTransit;
        case 'shippsy-approval':
          return this.$store.state.main.settings.color.shippsyApproval;
        case 'ready-for-pickup':
          return this.$store.state.main.settings.color.readyForPickup;
        case 'complete':
          return this.$store.state.main.settings.color.complete;
      }

      return '';
    },

    // column settings
    async getManageColumnSettings(table) {
      try {
        const response = await this.$http.get(`manage-columns/${table}`);

        return response.data || [];
      } catch (ex) {
        return [];
      }
    },

    getSortedColumnHeaders(headers, columnSettings) {
      let sortedHeaders = headers;
      // if settings is empty then use default headers
      if (columnSettings.length == 0) {
        return sortedHeaders;
      }

      // order based on saved settings
      sortedHeaders = [];
      columnSettings.forEach((column) => {
        const header = _find(headers, { value: column.value });
        if (header) {
          sortedHeaders.push(header);
        }
      });

      // add columns not stored in user settings
      _differenceBy(headers, sortedHeaders, 'value').forEach((header) => {
        sortedHeaders.push(header);
      });

      return sortedHeaders;
    },

    buildManageColumnHeaders(headers, columnSettings, tableKey) {
      // if settings is empty then use default headers
      if (columnSettings.length == 0) {
        columnSettings = TABLE_COLUMNS.DEFAULT_TABLE_COLUMNS[tableKey] || [];
      }

      return this.getSortedColumnHeaders(headers, columnSettings).map(
        (header) => {
          const column = _find(columnSettings, { value: header.value });
          header.visible = Boolean(column?.visible || false);

          // set subcolumns visibility
          (header.columns || []).map((subCol) => {
            subCol.visible = Boolean(
              _find(column.columns || [], { value: subCol.value })?.visible ||
                false
            );

            return subCol;
          });

          return header;
        }
      );
    },

    filteredColumnHeaders(headers, columnSettings, tableKey) {
      // if settings is empty then use default headers
      if (columnSettings.length == 0) {
        columnSettings = TABLE_COLUMNS.DEFAULT_TABLE_COLUMNS[tableKey] || [];
      }

      return this.getSortedColumnHeaders(headers, columnSettings).filter(
        (header) => {
          return Boolean(
            _find(columnSettings, { value: header.value })?.visible || false
          );
        }
      );
    },
    isSubColumnVisible(columnSettings, subColumnKey, subColumnValue) {
      const items = _find(columnSettings, { value: subColumnKey });

      return Boolean(
        _find(items.columns || [], { value: subColumnValue })?.visible || false
      );
    },

    asset: window.Vapor.asset,
    async downloadFileUrl(url, fileName, type = 'application/pdf'){
      try {
          var instance = axios.create();
          const response = await instance.get(url, {
              responseType: 'arraybuffer',
          });
              const blob = new Blob([response.data], { type: type });
              // Fallback method for downloading in a web browser
              const downloadUrl = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = downloadUrl;
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
      } catch (error) {
          console.error('Download failed:', error);
      }
    },
    async printFileUrl(url, type = 'application/pdf'){
        try {
            var instance = axios.create();
            const response = await instance.get(url, {
                responseType: 'arraybuffer',
            });
                    const blob = new Blob([response.data], {
                        type: type
                    });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
  
                    printJS(link.href);
        } catch (error) {
            console.error('Print failed:', error);
        }
    },
  },
 
};
