// Shipments
// const ShipmentsIndex = () => import('../pages/main/shipments/index.vue');
const ShipmentsActionRequiredIndex = () =>
  import('../pages/main/shipments/ActionRequired.vue');
const ShipmentsOrdersIndex = () => import('../pages/main/orders/index.vue');
const OrderView = () => import('../pages/main/orders/view.vue');

const Credit = () => import('../pages/main/credits/index.vue');

const SupportPage = () => import('../pages/main/support/index.vue');

const BOL = () => import('../pages/main/user/invoices.vue');
const ShipmentAddress = () => import('../pages/main/user/account-address.vue');
const Dashboard = () => import('../pages/main/dashboard/index.vue');

const routes = [
  {
    path: '/settings',
    component: () => import('../pages/main/settings/index.vue'),
    meta: {
      conditionalRoute: true,
      layout: 'main-layout',
      auth: true
    }
  },
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/payments',
    component: Credit,
    meta: {
      conditionalRoute: true,
      layout: 'main-layout',
      auth: true
    }
  },
  {
    path: '/invoices',
    component: BOL,
    meta: {
      conditionalRoute: true,
      layout: 'main-layout',
      auth: true
    }
  },
  {
    path: '/support',
    component: SupportPage,
    meta: {
      conditionalRoute: true,
      layout: 'main-layout',
      auth: true
    }
  },

  {
    path: '/us-address',
    component: ShipmentAddress,
    meta: {
      conditionalRoute: true,
      layout: 'main-layout',
      auth: true
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      conditionalRoute: true,
      layout: 'main-layout',
      auth: true
    }
  },
  // {
  //   path: '/shipments/:filter?',
  //   name: 'user-return-index',
  //   props: true,
  //   meta: {
  //     auth: true,
  //     layout: 'main-layout',
  //     shipments: true
  //   },
  //   component: ShipmentsIndex
  // },
  {
    path: '/shipments/action-required',
    name: 'users-shipments-action-required',
    props: true,
    meta: {
      auth: true,
      layout: 'main-layout',
      shipments: true
    },
    component: ShipmentsActionRequiredIndex
  },
  {
    path: '/orders',
    name: 'user-return-orders',
    props: true,
    meta: {
      auth: true,
      layout: 'main-layout',
      shipments: true
    },
    component: ShipmentsOrdersIndex
  },
  {
    path: '/orders/:id',
    name: 'user-order-view',
    props: true,
    meta: {
      auth: true,
      layout: 'main-layout',
      shipments: true
    },
    component: OrderView
  }
];

export default routes;
