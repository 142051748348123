import { App } from '@capacitor/app';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import axios from 'axios';
Vue.axios = window.axios;

import mainroutes from './main.js';
import adminroutes from './admin.js';
import authroutes from './auth.js';
import store from '../store';

Vue.use(VueRouter);
Vue.use(VueAxios, axios);

const notFound = [
  {
    path: '*',
    name: 'not-found',
    component: () => import('../pages/shared/not-found.vue'),
    meta: {
      layout: 'auth-layout'
    }
  }
];

const routes = [...adminroutes, ...authroutes, ...mainroutes, ...notFound];
const router = new VueRouter({
  routes, // short for routes: routes
  mode: 'history' // removes # from routes
});

Vue.router = router;

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  if (to.matched.some((record) => record.meta.auth)) {
    if (!store.state.auth.authenticated) {
      next({
        path: '/login'
      });
    } else if (
      store.state.auth.user.is_two_factor_required &&
      !store.state.auth.user.has_two_factor_enabled
    ) {
      next({
        path: '/two-factor-auth'
      });
    } else if (to.matched.some((record) => record.meta.reg)) {
      next();
    } else if (!store.state.auth.user.verified) {
      next({
        path: '/send-verification'
      });
    } else if (
      !store.state.auth.user.account_setup ||
      store.state.auth.user.return_agree != 1
    ) {
      next({
        path: '/account-setup'
      });
    } else if (
      !store.state.auth.user.phone_verified &&
      store.state.auth.user.auto_referral
    ) {
      next({
        path: '/verify-phone'
      });
      return;
    } else if (store.state.auth.user.locked == 1) {
      next({
        path: '/locked'
      });
      return;
    }

    if (to.matched.some((record) => record.meta.permissions)) {
      to.meta.permissions.forEach(function (element) {
        if (store.state.auth?.user?.can[element] !== true) {
          next({
            path: '/'
          });
          return;
        }
      });
    }
    next();
  } else {
    next();
  }
});

App.addListener('appUrlOpen', function (event) {
  // Example url: https://portal.shippsy.com/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.split('.com').pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug
    });
  }
});

export default router;
