import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import router from '../router/index';
import store from '../store';
import constants from '../constants';

axios.defaults.baseURL =
  (import.meta.env.VITE_VUE_APP_BASE_URL || window.location.origin) +
  ( import.meta.env.VITE_API_PATH || (constants.ALLOWED_HOSTS.includes(window.location.host)  ? '/api' : '/app'));

var token = localStorage.getItem('access_token');

if (token) {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('access_token')}`;
}

axios.defaults.headers.common['Accept'] = 'application/json';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // check for errorHandle config
    if (
      Object.prototype.hasOwnProperty.call(error, 'errorHandle') &&
      error.config.errorHandle === false
    ) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status == 401) {
      Vue.notify({
        group: 'main',
        title: 'Unauthorized',
        text: 'Please login to continue',
        duration: 8000,
        type: 'error'
      });

      localStorage.removeItem('vuex');

      store.dispatch('clearAll');
      router.push({ path: '/login' });
    } else if (error.response && error.response.status == 500) {
      Vue.notify({
        group: 'main',
        title: 'Server Error',
        text: 'An internal server error occurred.',
        duration: 8000,
        type: 'error'
      });
    } else if (error.response && error.response.status == 422) {
      if (typeof error.response.data.errors !== 'undefined') {
        const errors = error.response.data.errors;
        let message = '';

        for (var field in errors) {
          if (field != 'suggestions') {
            console.log('field :' + field);
            console.log(errors[field]);
            if (
              typeof errors[field] === 'string' ||
              errors[field] instanceof String
            ) {
              message += `${errors[field]}\n`;
            } else if (Array.isArray(errors[field])) {
              message += `${errors[field][0]}\n`;
            }
          }
        }

        Vue.notify({
          group: 'main',
          title: 'Validation Error',
          text: message,
          duration: 8000,
          type: 'error'
        });
      } else {
        Vue.notify({
          group: 'main',
          title: 'Validation Error',
          text: 'The data submitted did not pass validation.',
          duration: 8000,
          type: 'error'
        });
      }
    } else if (error.response && error.response.status == 404) {
      Vue.notify({
        group: 'main',
        title: 'Not Found',
        text: 'The requested data could not be found on the server.',
        duration: 8000,
        type: 'error'
      });
    } else if (error.response && error.response.status == 403) {
      Vue.notify({
        group: 'main',
        title: 'Unauthorized Action',
        text: 'You are not authorized to perform this action.',
        duration: 8000,
        type: 'error'
      });
    } else if (error.response && error.response.status == 419) {
      Vue.notify({
        group: 'main',
        title: 'Session Expired',
        text: 'Your session has expired.',
        duration: 8000,
        type: 'error'
      });
      // refresh on csrf token invalid
      // window.location.reload();
    }

    return Promise.reject(error);
  }
);

Vue.use(VueAxios, axios);
