import { Capacitor } from '@capacitor/core';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import Vue from 'vue';
import store from '../store';
const AuthPlugin = {};
import axios from 'axios';
import constants from '../constants';

AuthPlugin.install = function (Vue) {
  Vue.mixin({
    computed: {
      isAllowedHost() {
        return constants.ALLOWED_HOSTS.includes(window.location.host);
      }
    },
    methods: {
      logout(redirect = true) {
        this.$http({
          method: 'post',
          url: '/auth/logout'
        }).then(() => {
          localStorage.removeItem('vuex');
          localStorage.removeItem('access_token');

          this.$store.dispatch('clearAll');

          if (redirect) {
            // this.$router.push({ path: '/login' });
            // reload browser to refresh pudo/shippsy design
            location.replace('/');
          }
        });
      },
      browserLogin(
        email,
        password,
        remember = false,
        url = '/auth/login',
        redirect = '/'
      ) {
        return new Promise((resolve, reject) => {
          this.getCsrf().then(() => {
            this.$http
              .post(url, {
                email,
                password,
                remember
              })
              .then((res) => {
                if (res.data.group_token) {
                  this.$cookies.set(
                    'group-token',
                    res.data.group_token,
                    60 * 60 * 24
                  );
                }

                this.getUser().then(() => {
                  this.$router.push(redirect);
                });

                resolve(res.data);
              })
              .catch((res) => {
                reject(res);
              });
          });
        });
      },
      login(
        email,
        password,
        remember = false,
        url = '/auth/login',
        redirect = '/'
      ) {
        if (this.isAllowedHost) {
          return this.browserLogin(email, password, remember, url, redirect);
        } else {
          return this.appLogin(email, password, remember, redirect);
        }
      },
      // this is the mobile login
      appLogin(email, password, remember = false, redirect = '/') {
        return new Promise((resolve, reject) => {
          this.$http
            .post('/sanctum/token', {
              email,
              password,
              remember
            })
            .then(async (res) => {
              if (Capacitor.getPlatform() === 'ios') {
                await SavePassword.promptDialog({
                  username: email,
                  password: password
                });
              }

              if (res.data) {
                localStorage.setItem('access_token', res.data);
              }

              var token = localStorage.getItem('access_token');

              if (token) {
                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${localStorage.getItem('access_token')}`;
              }

              if (res.data.group_token) {
                this.$cookies.set(
                  'group-token',
                  res.data.group_token,
                  60 * 60 * 24
                );
              }

              this.getUser().then(() => {
                this.$router.push(redirect);
              });

              resolve(res.data);
            })
            .catch((res) => {
              reject(res);
            });
        });
      },
      getCsrf() {
        return new Promise((resolve, reject) => {
          this.$http({
            method: 'get',
            url: '/sanctum/csrf-cookie',
            baseURL: '/'
          })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      },
      getUser() {
        return new Promise((resolve, reject) => {
          this.$http
            .get('/auth/user')
            .then((res) => {
              this.$store.commit('auth/setUser', res.data.data);
              this.$store.commit('auth/setAuthenticated', true);
              resolve(res.data.data);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    }
  });

  Vue.prototype.$auth = store.state.auth;
  Vue.auth = store.state.auth;
};

Vue.use(AuthPlugin);
