import Vue from 'vue';

// layouts
Vue.component('MainLayout', () =>
  import(/* webpackChunkName: "main" */ '../components/layouts/MainLayout.vue')
);

Vue.component('SettingInputOption', () =>
  import(/* webpackChunkName: "main" */ './shared/SettingInputOption.vue')
);

Vue.component('FooterMobileMenu', () =>
  import(
    /* webpackChunkName: "main" */ '../components/layouts/FooterMobileMenu.vue'
  )
);
