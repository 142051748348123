export default {
  DEFAULT_TABLE_COLUMNS: {
    table_columns_client_shipments: [
      {
        value: 'shipment_id',
        visible: true,
      },
      // {
      //   value: 'items',
      //   visible: false,
      // },
      // {
      //   value: 'sender',
      //   visible: false,
      // },
      { value: 'barcode', visible: true },
      { value: 'tracking_number', visible: true },
      { value: 'created_at', visible: true },
      { value: 'status_detail', visible: true },
      { value: 'status', visible: true },
      // { value: 'actions', visible: true }
    ]
  }
};
