import 'babel-polyfill';

import './bootstrap';
// import '@fortawesome/fontawesome-free/css/all.css';

// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';

// window.Pusher = Pusher;

// window.Echo = new Echo({
//   broadcaster: 'pusher',
//   key: window.Laravel.pusher_key,
//   cluster: 'us2',
//   forceTLS: true
// });

import Vue from 'vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store';
import mixin from './mixins/mixin';

import './filter';
import './components';

import './plugins/auth';
import 'vuetify/dist/vuetify.min.css';
import './plugins/vue-signature-pad';
import './plugins/vue-clipboard2';
import './plugins/vue-the-mask';
import './plugins/vue-barcode-scanner';
import './plugins/vee-validate';
import './plugins/moment';
import './plugins/file-upload';
import './plugins/vue-notification';
import './plugins/axios';
import './plugins/google-maps';
import './plugins/vue-meta';
import './plugins/vue-cookies';
import './plugins/sentry';

import printJS from 'print-js';
import swal from 'sweetalert';
import Vapor from "laravel-vapor";
window.printJS = printJS;
window.swal = swal;

Vue.mixin(mixin);
window.Vue = Vue;
window.vapor = Vapor;
window.Event = new (class {
  constructor() {
    this.vue = new Vue();
  }
  fire(event, data = null) {
    this.vue.$emit(event, data);
  }
  listen(event, callback) {
    this.vue.$on(event, callback);
  }
})();

window.bus = new Vue();
window.bus1 = new Vue();
import App from './App.vue';
new Vue({
  el: '#app',
  render: (h) => h(App),
  router: router,
  store,
  vuetify
});
