import Vue from 'vue';

Vue.component('AdminLayout', () =>
  import(/* webpackChunkName: "adm" */ '../components/layouts/AdminLayout.vue')
);
Vue.component('EditEmployeeBranch', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/ChangeEmployeeBranch.vue'
  )
);

Vue.component('UserSearch', () =>
  import('../components/admin/users/SearchUser.vue')
);

Vue.component('AdminShipmentsTable', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/ShipmentsTable.vue'
  )
);

Vue.component('AdjustShipmentDuty', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/AdjustShipmentDuty.vue'
  )
);

Vue.component('AdminReturnDetails', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/ShipmentDetails.vue'
  )
);

Vue.component('AdminReturnsLabelTable', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/ShipmentsTable.vue'
  )
);

// Vue.component('recipient-address',  require('../components/shipments/single-flow/recipient_address.vue'));

Vue.component('CreateReturn', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/CreateShipment.vue'
  )
);

Vue.component('PrepareShipment', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/PrepareShipment.vue'
  )
);

Vue.component('MeasureShipment', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/MeasureShipment.vue'
  )
);

Vue.component('ReviewReturnToSender', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/ReviewReturnToSender.vue'
  )
);

Vue.component('DisplayCharges', () =>
  import(
    /* webpackChunkName: "adm" */ '../components/admin/shipments/DisplayAdditionalCharges.vue'
  )
);

Vue.component('AccountHistoryTable', () =>
  import('../components/admin/users/AccountHistoryTable.vue')
);
