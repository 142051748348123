// Admin
const adminMain = () =>
  import('../pages/admin/index.vue');

// Shipments
const ShipmentsCreate = () =>
  import('../pages/admin/shipments/new.vue');
const ShipmentsIndex = () =>
  import('../pages/admin/shipments/index.vue');
const ShipmentsEdit = () =>
  import('../pages/admin/shipments/edit.vue');
const ShipmentsShow = () =>
  import('../pages/admin/shipments/show.vue');

const ShipmentsOrderIndex = () =>
  import('../pages/admin/orders/index.vue');
const ShipmentsOrderView = () =>
  import('../pages/admin/orders/view.vue');

// Users
const UsersIndex = () =>
  import('../pages/admin/users/index.vue');
const UserShow = () =>
  import('../pages/admin/users/show.vue');

const Analytics = () =>
  import('../pages/admin/analytics/index.vue');

const ShipmentTrips = () =>
  import('../pages/admin/trips/index.vue');
const ShipmentTripShow = () =>
  import('../pages/admin/trips/show.vue');

const Scan = () =>
  import(
    '../pages/admin/scan/scan-shipment-received.vue'
  );
const ScanOrder = () =>
  import(
    '../pages/admin/scan/scan-order-complete.vue'
  );

const UsScan = () =>
  import(
    '../pages/admin/us-employee/us-scan.vue'
  );
const RackScan = () =>
  import(
    '../pages/admin/us-employee/rack-scan.vue'
  );
const LabelsIndex = () =>
  import('../pages/admin/us-employee/labels.vue');

const DutiesUpload = () =>
  import('../pages/admin/duties/upload.vue');
const DutiesManual = () =>
  import('../pages/admin/duties/manual.vue');

const RackLabels = () =>
  import('../pages/admin/rack-labels/index.vue');

const PackingSlip = () =>
  import('../pages/admin/packing-slip/index.vue');

const RolesAndPermissionsIndex = () =>
  import(
    '../pages/admin/roles-and-permissions/index.vue'
  );

const EmployeeLocation = () =>
  import(
    '../pages/admin/employee-location/index.vue'
  );

const AddVendor = () =>
  import('../pages/admin/vendors/index.vue');

const SystemSettings = () =>
  import(
    '../pages/admin/system-settings/index.vue'
  );

const ScanDisposed = () =>
  import(
    '../pages/admin/scan/scan-shipments-disposed.vue'
  );
const UsSortScan = () =>
    import('../pages/admin/us-employee/sort-scan.vue');

const UsSort = () =>
    import('../pages/admin/us-employee/sort.vue');

const routes = [
  {
    path: '/admin/employee-location',
    name: 'admin-employee-location',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
      // permissions: ['access.admin']
    },
    component: EmployeeLocation
  },
  {
    path: '/admin/packing-slip',
    name: 'admin-packing-slip',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
      // permissions: ['admin.access', 'trips.manage']
    },
    component: PackingSlip
  },
  {
    path: '/admin/rack-labels',
    name: 'admin-rack-labels',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: RackLabels
  },

  {
    path: '/admin/trips/:tripNumber',
    name: 'admin-return-trip-show',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'trips.manage']
    },
    component: ShipmentTripShow
  },
  {
    path: '/admin/trips',
    name: 'admin-trips',
    // props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'trips.manage']
    },
    component: ShipmentTrips
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: adminMain
  },
  {
    path: '/admin/shipments/new',
    name: 'admin-return-create',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: ShipmentsCreate
  },
  {
    path: '/admin/shipments/:id',
    name: 'admin-return-show',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'shipments.view']
    },
    component: ShipmentsShow,
    props: true
  },

  {
    path: '/admin/shipments',
    name: 'admin-return-index',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'shipments.view']
    },
    component: ShipmentsIndex
  },
  {
    path: '/admin/shipments/:id/edit',
    name: 'admin-return-edit',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'shipments.edit']
    },
    component: ShipmentsEdit
  },

  {
    path: '/admin/analytics',
    name: 'admin-analytics',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'analytics.view']
    },
    component: Analytics
  },
  {
    path: '/admin/orders',
    name: 'admin-return-orders',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'shipments.view']
    },
    component: ShipmentsOrderIndex
  },

  {
    path: '/admin/orders/:id',
    props: true,
    name: 'admin-order-view',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'shipments.view']
    },
    component: ShipmentsOrderView
  },

  {
    path: '/admin/users',
    name: 'users-index',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'users.view']
    },
    component: UsersIndex
  },
  {
    path: '/admin/users/:id',
    name: 'admin-user-show',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'users.view']
    },
    component: UserShow
  },
  {
    path: '/admin/scan',
    name: 'admin-scan',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
      // permissions: ['admin.access', 'access-custom-pricing']
    },
    component: Scan
  },
  {
    path: '/admin/scan/order',
    name: 'admin-scan-order',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
      // permissions: ['admin.access', 'access-custom-pricing']
    },
    component: ScanOrder
  },
  {
    path: '/admin/us-scan',
    name: 'admin-us-scan',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: UsScan
  },
  {
    path: '/admin/rack-scan',
    name: 'admin-rack-scan',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: RackScan
  },
  {
    path: '/admin/labels/shipments/',
    name: 'admin-return-labels',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: LabelsIndex
  },

  {
    path: '/admin/duties/upload',
    name: 'admin-duties-upload',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access', 'images.upload']
    },
    component: DutiesUpload
  },
  {
    path: '/admin/duties/manual',
    name: 'admin-duties-manual',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
      // permissions: ['admin.access', 'shipments.print_lables']
    },
    component: DutiesManual
  },
  {
    path: '/admin/roles-and-permissions',
    name: 'admin-roles-and-permissions-index',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
    },
    component: RolesAndPermissionsIndex
  },
  {
    path: '/admin/add-vendor',
    name: 'add-vendor',
    props: true,
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
    },
    component: AddVendor
  },
  {
    path: '/admin/regions',
    name: 'admin-regions',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['regions.edit']
    },
    component: () =>
      import('../pages/admin/regions/index.vue')
  },
  {
    path: '/admin/locations',
    name: 'admin-locations',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['locations.edit']
    },
    component: () =>
      import('../pages/admin/locations/index.vue')
  },
  {
    path: '/admin/prohibited-items',
    name: 'prohibited-items',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['prohibited_items.manage']
    },
    component: () =>
      import(
        '../pages/admin/prohibited-items/index.vue'
      )
  },
  {
    path: '/admin/system-settings',
    name: 'admin-system-settings',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['system_settings.manage']
    },
    component: SystemSettings
  },
  {
    path: '/admin/scan/disposed',
    name: 'admin-san-disposed-shipment',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout'
    },
    component: ScanDisposed
  },
  {
    path: '/admin/us-sort-scan',
    name: 'admin-us-sort-scan',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: UsSortScan
  },
  {
    path: '/admin/us-sort',
    name: 'admin-us-sort',
    meta: {
      auth: true,
      admin: true,
      layout: 'admin-layout',
      permissions: ['admin.access']
    },
    component: UsSort
  },
];

export default routes;
