// Make sure to call Vue.use(Vuex) first if using a module system
import Vue from 'vue';
import Vuex from 'vuex';
import main from './modules/main';
import auth from './modules/auth';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth']
});

const store = new Vuex.Store({
  modules: {
    main, auth
  },
  actions: {
    clearAll({ commit }) {
      commit('auth/reset');
      commit('main/reset');
    }
  },
  plugins: [vuexLocal.plugin]
});

export default store;
