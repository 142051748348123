import Vue from 'vue';
import moment from 'moment-timezone';
import Vue2Filters from 'vue2-filters';

Vue.use(Vue2Filters);

function getTimezone() {
  try {
    return Vue.auth.user.timezone;
  } catch (err) {
    console.error(err);
  }
  return 'America/Toronto';
}

Vue.filter('datetime_pretty', (value) => {
  const customerTimezone = getTimezone();

  try {
    var est = moment.tz(value, 'America/Toronto');
    var converted = est.clone().tz(customerTimezone);

    return converted.format('MMMM DD YYYY, h:mm:ss A');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('date_pretty', (value) => {
  var customerTimezone = getTimezone();
  console.log(customerTimezone);

  try {
    var est = moment.tz(value, 'America/Toronto');
    var converted = est.clone().tz(customerTimezone);

    return converted.format('MMMM DD YYYY');
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('humanize', (str) => {
  try {
    var i,
      frags = str.split('_');
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
  } catch (err) {
    return str;
  }
});

Vue.filter('breakdown_pretty', (obj) => {
  if (obj) {
    let array = [];

    Object.keys(obj).forEach((key) => {
      array.push(
        `${Vue.filter('humanize')(key)} ${Vue.filter('currency')(obj[key])}`
      );
    });

    return array.join(', ');
  }

  return obj;
});

Vue.filter('datetime_from', (value) => {
  const timezone = getTimezone();
  try {
    var est = moment.tz(value, 'America/Toronto');
    var start = est.clone().tz(timezone);
    var end = moment.tz(timezone);

    return start.from(end);
  } catch (err) {
    console.error(err);
    return value;
  }
});

Vue.filter('myFilter', (value) => {
  return value.toUpperCase();
});

Vue.filter('phone', function (phone) {
  if (phone)
    return phone
      .replace(/[^0-9]/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('status', function (value) {
  if (
    value.toLowerCase() == 'pending' ||
    value.toLowerCase() == 'unpaid' ||
    value.toLowerCase() == 'postage expired'
  ) {
    return '<i class="mdi mdi-pause-circle-outline"></i> ' + value;
  }
  if (value.toLowerCase() == 'incomplete') {
    return '<i class="mdi mdi-circle-edit-outline"></i> ' + value;
  }
  if (value.toLowerCase() == 'postage expired') {
    return '<i class="mdi mdi-alert"></i> ' + value;
  } else if (value.toLowerCase() == 'pending') {
    return '<i class="mdi mdi-pause-circle-outline"></i> ' + value;
  } else if (value.toLowerCase() == 'ready') {
    return '<i class="mdi mdi-cube-outline"></i> ' + value;
  } else if (value.toLowerCase() == 'processing') {
    return '<i class="mdi mdi-marker-check"></i> ' + value;
  } else if (value.toLowerCase() == 'received') {
    return '<i class="mdi mdi-marker-check"></i> ' + value;
  } else if (value.toLowerCase() == 'in transit') {
    return '<i class="mdi mdi-cube-send"></i> ' + value;
  } else if (
    value.toLowerCase() == 'delivered' ||
    value.toLowerCase() == 'complete'
  ) {
    return '<i class="mdi mdi-check"></i> ' + value;
  } else if (value.toLowerCase() == 'exception') {
    return '<i class="mdi mdi-alert-circle-outline"></i> ' + value;
  } else if (value.toLowerCase() == 'voided') {
    return '<i class="mdi mdi-select-off"></i> ' + value;
  } else if (value.toLowerCase() == 'void requested') {
    return '<i class="mdi mdi-select"></i> ' + value;
  } else if (value.toLowerCase() == 'all') {
    return '<i class="mdi mdi-format-list-bulleted"></i> ' + value;
  } else {
    return value;
  }
});

Vue.filter('status', function (value) {
  switch (value) {
    case 0:
      return 'Unprocessed';

    case 1:
      return 'Pending Details';

    case 3:
      return 'Hold';

    case 5:
      return 'Awaiting Approval';

    case 10:
      return 'Ready To Ship';

    case 15:
      return 'US Disposal';

    case 16:
      return 'Pre Sorting';

    case 17:
      return 'Sorting';

    case 20:
      return 'Sorted';

    case 25:
      return 'In Transit';

    case 30:
      return 'In Canada';

    case 32:
      return 'Mail Out';

    case 35:
      return 'Complete';

    case 36:
      return 'Error';

    case 37:
      return 'Details Provided';

    default:
      return value;
  }
});

Vue.filter('service', function (value) {
  switch (value) {
    case 1:
      return 'Action Required';

    case 2:
      return 'Item Requested';

    case 3:
      return 'Dispose';

    case 4:
      return 'Auto-Disposal';

    case 5:
      return 'US-Disposal';

    case 6:
      return 'Return to Sender';

    default:
      return value;
  }
});

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB';
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB';
  }
  return size.toString() + ' B';
});

Vue.filter('file_download', function (value) {
  if (!value) return '';
  return value.replace(/^.*[\\/]/, '');
});
