function initialState() {
    return {
      user: null,
      authenticated: false,
      ready: true,
      loaded: true
    };
  }
  const main = {
    namespaced: true,
    state: initialState(),
    actions: {},
    mutations: {
      setUser(state, user) {
        state.user = user;
      },
      setAuthenticated(state, value) {
        state.authenticated = value;
      },
      reset(state) {
        const s = initialState();
        Object.keys(s).forEach((key) => {
          state[key] = s[key];
        });
      }
    },
    getters: {
      authCheck: (state) => state.user != null,
      authUser: (state) => state.user
    }
  };
  
  export default main;
  
  