export default {
  ALLOWED_HOSTS: [
    'portal.shippsy.test',
    'admin.shippsy.test',
    'sandbox.shippsy.com',
    'adminsandbox.shippsy.com',
    'portal.shippsy.com',
    'admin.shippsy.com'
  ],
  COUNTRIES: [
    {
      name: 'United States(US)',
      code: 'US'
    },
    {
      name: 'Canada(CA)',
      code: 'CA'
    },
    {
      name: 'Afghanistan(AF)',
      code: 'AF'
    },
    {
      name: 'land Islands(AX)',
      code: 'AX'
    },
    {
      name: 'Albania(AL)',
      code: 'AL'
    },
    {
      name: 'Algeria(DZ)',
      code: 'DZ'
    },
    // {
    //     "name": "American Samoa(AS)",
    //     "code": "AS"
    // },
    {
      name: 'AndorrA(AD)',
      code: 'AD'
    },
    {
      name: 'Angola(AO)',
      code: 'AO'
    },
    {
      name: 'Anguilla(AI)',
      code: 'AI'
    },
    {
      name: 'Antarctica(AQ)',
      code: 'AQ'
    },
    {
      name: 'Antigua and Barbuda(AG)',
      code: 'AG'
    },
    {
      name: 'Argentina(AR)',
      code: 'AR'
    },
    {
      name: 'Armenia(AM)',
      code: 'AM'
    },
    {
      name: 'Aruba(AW)',
      code: 'AW'
    },
    {
      name: 'Australia(AU)',
      code: 'AU'
    },
    {
      name: 'Austria(AT)',
      code: 'AT'
    },
    {
      name: 'Azerbaijan(AZ)',
      code: 'AZ'
    },
    {
      name: 'Bahamas(BS)',
      code: 'BS'
    },
    {
      name: 'Bahrain(BH)',
      code: 'BH'
    },
    {
      name: 'Bangladesh(BD)',
      code: 'BD'
    },
    {
      name: 'Barbados(BB)',
      code: 'BB'
    },
    {
      name: 'Belarus(BY)',
      code: 'BY'
    },
    {
      name: 'Belgium(BE)',
      code: 'BE'
    },
    {
      name: 'Belize(BZ)',
      code: 'BZ'
    },
    {
      name: 'Benin(BJ)',
      code: 'BJ'
    },
    {
      name: 'Bermuda(BM)',
      code: 'BM'
    },
    {
      name: 'Bhutan(BT)',
      code: 'BT'
    },
    {
      name: 'Bolivia(BO)',
      code: 'BO'
    },
    {
      name: 'Bosnia and Herzegovina(BA)',
      code: 'BA'
    },
    {
      name: 'Botswana(BW)',
      code: 'BW'
    },
    {
      name: 'Bouvet Island(BV)',
      code: 'BV'
    },
    {
      name: 'Brazil(BR)',
      code: 'BR'
    },
    {
      name: 'British Indian Ocean Territory(IO)',
      code: 'IO'
    },
    {
      name: 'Brunei Darussalam(BN)',
      code: 'BN'
    },
    {
      name: 'Bulgaria(BG)',
      code: 'BG'
    },
    {
      name: 'Burkina Faso(BF)',
      code: 'BF'
    },
    {
      name: 'Burundi(BI)',
      code: 'BI'
    },
    {
      name: 'Cambodia(KH)',
      code: 'KH'
    },
    {
      name: 'Cameroon(CM)',
      code: 'CM'
    },

    {
      name: 'Cape Verde(CV)',
      code: 'CV'
    },
    {
      name: 'Cayman Islands(KY)',
      code: 'KY'
    },
    {
      name: 'Central African Republic(CF)',
      code: 'CF'
    },
    {
      name: 'Chad(TD)',
      code: 'TD'
    },
    {
      name: 'Chile(CL)',
      code: 'CL'
    },
    {
      name: 'China(CN)',
      code: 'CN'
    },
    {
      name: 'Christmas Island(CX)',
      code: 'CX'
    },
    {
      name: 'Cocos (Keeling) Islands(CC)',
      code: 'CC'
    },
    {
      name: 'Colombia(CO)',
      code: 'CO'
    },
    {
      name: 'Comoros(KM)',
      code: 'KM'
    },
    {
      name: 'Congo(CG)',
      code: 'CG'
    },
    {
      name: 'Congo, The Democratic Republic of the(CD)',
      code: 'CD'
    },
    {
      name: 'Cook Islands(CK)',
      code: 'CK'
    },
    {
      name: 'Costa Rica(CR)',
      code: 'CR'
    },
    {
      name: 'Cote D"Ivoire(CI)',
      code: 'CI'
    },
    {
      name: 'Croatia(HR)',
      code: 'HR'
    },
    {
      name: 'Cuba(CU)',
      code: 'CU'
    },
    {
      name: 'Cyprus(CY)',
      code: 'CY'
    },
    {
      name: 'Czech Republic(CZ)',
      code: 'CZ'
    },
    {
      name: 'Denmark(DK)',
      code: 'DK'
    },
    {
      name: 'Djibouti(DJ)',
      code: 'DJ'
    },
    {
      name: 'Dominica(DM)',
      code: 'DM'
    },
    {
      name: 'Dominican Republic(DO)',
      code: 'DO'
    },
    {
      name: 'Ecuador(EC)',
      code: 'EC'
    },
    {
      name: 'Egypt(EG)',
      code: 'EG'
    },
    {
      name: 'El Salvador(SV)',
      code: 'SV'
    },
    {
      name: 'Equatorial Guinea(GQ)',
      code: 'GQ'
    },
    {
      name: 'Eritrea(ER)',
      code: 'ER'
    },
    {
      name: 'Estonia(EE)',
      code: 'EE'
    },
    {
      name: 'Ethiopia(ET)',
      code: 'ET'
    },
    {
      name: 'Falkland Islands(FK)',
      code: 'FK'
    },
    {
      name: 'Faroe Islands(FO)',
      code: 'FO'
    },
    {
      name: 'Fiji(FJ)',
      code: 'FJ'
    },
    {
      name: 'Finland(FI)',
      code: 'FI'
    },
    {
      name: 'France(FR)',
      code: 'FR'
    },
    {
      name: 'French Guiana(GF)',
      code: 'GF'
    },
    {
      name: 'French Polynesia(PF)',
      code: 'PF'
    },
    {
      name: 'French Southern Territories(TF)',
      code: 'TF'
    },
    {
      name: 'Gabon(GA)',
      code: 'GA'
    },
    {
      name: 'Gambia(GM)',
      code: 'GM'
    },
    {
      name: 'Georgia(GE)',
      code: 'GE'
    },
    {
      name: 'Germany(DE)',
      code: 'DE'
    },
    {
      name: 'Ghana(GH)',
      code: 'GH'
    },
    {
      name: 'Gibraltar(GI)',
      code: 'GI'
    },
    {
      name: 'Greece(GR)',
      code: 'GR'
    },
    {
      name: 'Greenland(GL)',
      code: 'GL'
    },
    {
      name: 'Grenada(GD)',
      code: 'GD'
    },
    {
      name: 'Guadeloupe(GP)',
      code: 'GP'
    },
    // {
    //     "name": "Guam(GU)",
    //     "code": "GU"
    // },
    {
      name: 'Guatemala(GT)',
      code: 'GT'
    },
    {
      name: 'Guernsey(GG)',
      code: 'GG'
    },
    {
      name: 'Guinea(GN)',
      code: 'GN'
    },
    {
      name: 'Guinea-Bissau(GW)',
      code: 'GW'
    },
    {
      name: 'Guyana(GY)',
      code: 'GY'
    },
    {
      name: 'Haiti(HT)',
      code: 'HT'
    },
    {
      name: 'Heard Island and Mcdonald Islands(HM)',
      code: 'HM'
    },
    {
      name: 'Vatican City(VA)',
      code: 'VA'
    },
    {
      name: 'Honduras(HN)',
      code: 'HN'
    },
    {
      name: 'Hong Kong(HK)',
      code: 'HK'
    },
    {
      name: 'Hungary(HU)',
      code: 'HU'
    },
    {
      name: 'Iceland(IS)',
      code: 'IS'
    },
    {
      name: 'India(IN)',
      code: 'IN'
    },
    {
      name: 'Indonesia(ID)',
      code: 'ID'
    },
    {
      name: 'Iran, Islamic Republic Of(IR)',
      code: 'IR'
    },
    {
      name: 'Iraq(IQ)',
      code: 'IQ'
    },
    {
      name: 'Ireland(IE)',
      code: 'IE'
    },
    {
      name: 'Isle of Man(IM)',
      code: 'IM'
    },
    {
      name: 'Israel(IL)',
      code: 'IL'
    },
    {
      name: 'Italy(IT)',
      code: 'IT'
    },
    {
      name: 'Jamaica(JM)',
      code: 'JM'
    },
    {
      name: 'Japan(JP)',
      code: 'JP'
    },
    {
      name: 'Jersey(JE)',
      code: 'JE'
    },
    {
      name: 'Jordan(JO)',
      code: 'JO'
    },
    {
      name: 'Kazakhstan(KZ)',
      code: 'KZ'
    },
    {
      name: 'Kenya(KE)',
      code: 'KE'
    },
    {
      name: 'Kiribati(KI)',
      code: 'KI'
    },
    {
      name: 'Korea, Democratic People"S Republic of(KP)',
      code: 'KP'
    },
    {
      name: 'Korea, Republic of(KR)',
      code: 'KR'
    },
    {
      name: 'Kuwait(KW)',
      code: 'KW'
    },
    {
      name: 'Kyrgyzstan(KG)',
      code: 'KG'
    },
    {
      name: 'Lao People"S Democratic Republic(LA)',
      code: 'LA'
    },
    {
      name: 'Latvia(LV)',
      code: 'LV'
    },
    {
      name: 'Lebanon(LB)',
      code: 'LB'
    },
    {
      name: 'Lesotho(LS)',
      code: 'LS'
    },
    {
      name: 'Liberia(LR)',
      code: 'LR'
    },
    {
      name: 'Libyan Arab Jamahiriya(LY)',
      code: 'LY'
    },
    {
      name: 'Liechtenstein(LI)',
      code: 'LI'
    },
    {
      name: 'Lithuania(LT)',
      code: 'LT'
    },
    {
      name: 'Luxembourg(LU)',
      code: 'LU'
    },
    {
      name: 'Macao(MO)',
      code: 'MO'
    },
    {
      name: 'Macedonia, The Former Yugoslav Republic of(MK)',
      code: 'MK'
    },
    {
      name: 'Madagascar(MG)',
      code: 'MG'
    },
    {
      name: 'Malawi(MW)',
      code: 'MW'
    },
    {
      name: 'Malaysia(MY)',
      code: 'MY'
    },
    {
      name: 'Maldives(MV)',
      code: 'MV'
    },
    {
      name: 'Mali(ML)',
      code: 'ML'
    },
    {
      name: 'Malta(MT)',
      code: 'MT'
    },
    {
      name: 'Marshall Islands(MH)',
      code: 'MH'
    },
    {
      name: 'Martinique(MQ)',
      code: 'MQ'
    },
    {
      name: 'Mauritania(MR)',
      code: 'MR'
    },
    {
      name: 'Mauritius(MU)',
      code: 'MU'
    },
    {
      name: 'Mayotte(YT)',
      code: 'YT'
    },
    {
      name: 'Mexico(MX)',
      code: 'MX'
    },
    {
      name: 'Micronesia, Federated States of(FM)',
      code: 'FM'
    },
    {
      name: 'Moldova, Republic of(MD)',
      code: 'MD'
    },
    {
      name: 'Monaco(MC)',
      code: 'MC'
    },
    {
      name: 'Mongolia(MN)',
      code: 'MN'
    },
    {
      name: 'Montenegro(ME)',
      code: 'ME'
    },
    {
      name: 'Montserrat(MS)',
      code: 'MS'
    },
    {
      name: 'Morocco(MA)',
      code: 'MA'
    },
    {
      name: 'Mozambique(MZ)',
      code: 'MZ'
    },
    {
      name: 'Myanmar(MM)',
      code: 'MM'
    },
    {
      name: 'Namibia(NA)',
      code: 'NA'
    },
    {
      name: 'Nauru(NR)',
      code: 'NR'
    },
    {
      name: 'Nepal(NP)',
      code: 'NP'
    },
    {
      name: 'Netherlands(NL)',
      code: 'NL'
    },
    {
      name: 'Netherlands Antilles(AN)',
      code: 'AN'
    },
    {
      name: 'New Caledonia(NC)',
      code: 'NC'
    },
    {
      name: 'New Zealand(NZ)',
      code: 'NZ'
    },
    {
      name: 'Nicaragua(NI)',
      code: 'NI'
    },
    {
      name: 'Niger(NE)',
      code: 'NE'
    },
    {
      name: 'Nigeria(NG)',
      code: 'NG'
    },
    {
      name: 'Niue(NU)',
      code: 'NU'
    },
    {
      name: 'Norfolk Island(NF)',
      code: 'NF'
    },
    {
      name: 'Northern Mariana Islands(MP)',
      code: 'MP'
    },
    {
      name: 'Norway(NO)',
      code: 'NO'
    },
    {
      name: 'Oman(OM)',
      code: 'OM'
    },
    {
      name: 'Pakistan(PK)',
      code: 'PK'
    },
    {
      name: 'Palau(PW)',
      code: 'PW'
    },
    {
      name: 'Palestinian Territory, Occupied(PS)',
      code: 'PS'
    },
    {
      name: 'Panama(PA)',
      code: 'PA'
    },
    {
      name: 'Papua New Guinea(PG)',
      code: 'PG'
    },
    {
      name: 'Paraguay(PY)',
      code: 'PY'
    },
    {
      name: 'Peru(PE)',
      code: 'PE'
    },
    {
      name: 'Philippines(PH)',
      code: 'PH'
    },
    {
      name: 'Pitcairn(PN)',
      code: 'PN'
    },
    {
      name: 'Poland(PL)',
      code: 'PL'
    },
    {
      name: 'Portugal(PT)',
      code: 'PT'
    },
    // {
    //     "name": "Puerto Rico(PR)",
    //     "code": "PR"
    // },
    {
      name: 'Qatar(QA)',
      code: 'QA'
    },
    {
      name: 'Reunion(RE)',
      code: 'RE'
    },
    {
      name: 'Romania(RO)',
      code: 'RO'
    },
    {
      name: 'Russian Federation(RU)',
      code: 'RU'
    },
    {
      name: 'RWANDA(RW)',
      code: 'RW'
    },
    {
      name: 'Saint Helena(SH)',
      code: 'SH'
    },
    {
      name: 'Saint Kitts and Nevis(KN)',
      code: 'KN'
    },
    {
      name: 'Saint Lucia(LC)',
      code: 'LC'
    },
    {
      name: 'Saint Pierre and Miquelon(PM)',
      code: 'PM'
    },
    {
      name: 'Saint Vincent and the Grenadines(VC)',
      code: 'VC'
    },
    {
      name: 'Samoa(WS)',
      code: 'WS'
    },
    {
      name: 'San Marino(SM)',
      code: 'SM'
    },
    {
      name: 'Sao Tome and Principe(ST)',
      code: 'ST'
    },
    {
      name: 'Saudi Arabia(SA)',
      code: 'SA'
    },
    {
      name: 'Senegal(SN)',
      code: 'SN'
    },
    {
      name: 'Serbia(RS)',
      code: 'RS'
    },
    {
      name: 'Seychelles(SC)',
      code: 'SC'
    },
    {
      name: 'Sierra Leone(SL)',
      code: 'SL'
    },
    {
      name: 'Singapore(SG)',
      code: 'SG'
    },
    {
      name: 'Slovakia(SK)',
      code: 'SK'
    },
    {
      name: 'Slovenia(SI)',
      code: 'SI'
    },
    {
      name: 'Solomon Islands(SB)',
      code: 'SB'
    },
    {
      name: 'Somalia(SO)',
      code: 'SO'
    },
    {
      name: 'South Africa(ZA)',
      code: 'ZA'
    },
    {
      name: 'South Georgia and the South Sandwich Islands(GS)',
      code: 'GS'
    },
    {
      name: 'Spain(ES)',
      code: 'ES'
    },
    {
      name: 'Sri Lanka(LK)',
      code: 'LK'
    },
    {
      name: 'Sudan(SD)',
      code: 'SD'
    },
    {
      name: 'Suriname(SR)',
      code: 'SR'
    },
    {
      name: 'Svalbard and Jan Mayen(SJ)',
      code: 'SJ'
    },
    {
      name: 'Swaziland(SZ)',
      code: 'SZ'
    },
    {
      name: 'Sweden(SE)',
      code: 'SE'
    },
    {
      name: 'Switzerland(CH)',
      code: 'CH'
    },
    {
      name: 'Syrian Arab Republic(SY)',
      code: 'SY'
    },
    {
      name: 'Taiwan, Province of China(TW)',
      code: 'TW'
    },
    {
      name: 'Tajikistan(TJ)',
      code: 'TJ'
    },
    {
      name: 'Tanzania, United Republic of(TZ)',
      code: 'TZ'
    },
    {
      name: 'Thailand(TH)',
      code: 'TH'
    },
    {
      name: 'Timor-Leste(TL)',
      code: 'TL'
    },
    {
      name: 'Togo(TG)',
      code: 'TG'
    },
    {
      name: 'Tokelau(TK)',
      code: 'TK'
    },
    {
      name: 'Tonga(TO)',
      code: 'TO'
    },
    {
      name: 'Trinidad and Tobago(TT)',
      code: 'TT'
    },
    {
      name: 'Tunisia(TN)',
      code: 'TN'
    },
    {
      name: 'Turkey(TR)',
      code: 'TR'
    },
    {
      name: 'Turkmenistan(TM)',
      code: 'TM'
    },
    {
      name: 'Turks and Caicos Islands(TC)',
      code: 'TC'
    },
    {
      name: 'Tuvalu(TV)',
      code: 'TV'
    },
    {
      name: 'Uganda(UG)',
      code: 'UG'
    },
    {
      name: 'Ukraine(UA)',
      code: 'UA'
    },
    {
      name: 'United Arab Emirates(AE)',
      code: 'AE'
    },
    {
      name: 'United Kingdom(GB)',
      code: 'GB'
    },

    // {
    //     "name": "United States Minor Outlying Islands(UM)",
    //     "code": "UM"
    // },
    {
      name: 'Uruguay(UY)',
      code: 'UY'
    },
    {
      name: 'Uzbekistan(UZ)',
      code: 'UZ'
    },
    {
      name: 'Vanuatu(VU)',
      code: 'VU'
    },
    {
      name: 'Venezuela(VE)',
      code: 'VE'
    },
    {
      name: 'Viet Nam(VN)',
      code: 'VN'
    },
    {
      name: 'Virgin Islands, British(VG)',
      code: 'VG'
    },
    // {
    //     "name": "Virgin Islands, U.S.(VI)",
    //     "code": "VI"
    // },
    {
      name: 'Wallis and Futuna(WF)',
      code: 'WF'
    },
    {
      name: 'Western Sahara(EH)',
      code: 'EH'
    },
    {
      name: 'Yemen(YE)',
      code: 'YE'
    },
    {
      name: 'Zambia(ZM)',
      code: 'ZM'
    },
    {
      name: 'Zimbabwe(ZW)',
      code: 'ZW'
    }
  ],

  PROVINCES: [
    {
      name: 'Alberta(AB)',
      abbreviation: 'AB'
    },
    {
      name: 'British Columbia(BC)',
      abbreviation: 'BC'
    },
    {
      name: 'Manitoba(MB)',
      abbreviation: 'MB'
    },
    {
      name: 'New Brunswick(NB)',
      abbreviation: 'NB'
    },
    {
      name: 'Newfoundland and Labrador(NL)',
      abbreviation: 'NL'
    },
    {
      name: 'Northwest Territories(NT)',
      abbreviation: 'NT'
    },
    {
      name: 'Nova Scotia(NS)',
      abbreviation: 'NS'
    },
    {
      name: 'Nunavut(NU)',
      abbreviation: 'NU'
    },
    {
      name: 'Ontario(ON)',
      abbreviation: 'ON'
    },
    {
      name: 'Prince Edward Island(PE)',
      abbreviation: 'PE'
    },
    {
      name: 'Quebec(QC)',
      abbreviation: 'QC'
    },
    {
      name: 'Saskatchewan(SK)',
      abbreviation: 'SK'
    },
    {
      name: 'Yukon Territory(YT)',
      abbreviation: 'YT'
    }
  ],
  STATES: [
    {
      value: 'AK',
      text: 'Alaska(AK)'
    },
    {
      value: 'AL',
      text: 'Alabama(AL)'
    },
    {
      value: 'AR',
      text: 'Arkansas(AR)'
    },
    {
      value: 'AS',
      text: 'American Samoa(AS)'
    },
    {
      value: 'AZ',
      text: 'Arizona(AZ)'
    },
    {
      value: 'CA',
      text: 'California(CA)'
    },
    {
      value: 'CO',
      text: 'Colorado(CO)'
    },
    {
      value: 'CT',
      text: 'Connecticut(CT)'
    },
    {
      value: 'DC',
      text: 'District of Columbia(DC)'
    },
    {
      value: 'DE',
      text: 'Delaware(DE)'
    },
    {
      value: 'FL',
      text: 'Florida(FL)'
    },
    {
      value: 'GA',
      text: 'Georgia(GA)'
    },
    {
      value: 'GU',
      text: 'Guam(GU)'
    },
    {
      value: 'HI',
      text: 'Hawaii(HI)'
    },
    {
      value: 'IA',
      text: 'Iowa(ia)'
    },
    {
      value: 'ID',
      text: 'Idaho(ID)'
    },
    {
      value: 'IL',
      text: 'Illinois(IL)'
    },
    {
      value: 'IN',
      text: 'Indiana(IN)'
    },
    {
      value: 'KS',
      text: 'Kansas(KS)'
    },
    {
      value: 'KY',
      text: 'Kentucky(KY)'
    },
    {
      value: 'LA',
      text: 'Louisiana(LA)'
    },
    {
      value: 'MA',
      text: 'Massachusetts(MA)'
    },
    {
      value: 'MD',
      text: 'Maryland(MD)'
    },
    {
      value: 'ME',
      text: 'Maine(ME)'
    },
    {
      value: 'MI',
      text: 'Michigan(MI)'
    },
    {
      value: 'MN',
      text: 'Minnesota(MN)'
    },
    {
      value: 'MO',
      text: 'Missouri(MO)'
    },
    {
      value: 'MS',
      text: 'Mississippi(MS)'
    },
    {
      value: 'MT',
      text: 'Montana(MT)'
    },
    {
      value: 'NC',
      text: 'North Carolina(NC)'
    },
    {
      value: 'ND',
      text: 'North Dakota(ND)'
    },
    {
      value: 'NE',
      text: 'Nebraska(NE)'
    },
    {
      value: 'NH',
      text: 'New Hampshire(NH)'
    },
    {
      value: 'NJ',
      text: 'New Jersey(NJ)'
    },
    {
      value: 'NM',
      text: 'New Mexico(NM)'
    },
    {
      value: 'NV',
      text: 'Nevada(NV)'
    },
    {
      value: 'NY',
      text: 'New York(NY)'
    },
    {
      value: 'OH',
      text: 'Ohio(OH)'
    },
    {
      value: 'OK',
      text: 'Oklahoma(OK)'
    },
    {
      value: 'OR',
      text: 'Oregon(OR)'
    },
    {
      value: 'PA',
      text: 'Pennsylvania(PA)'
    },
    {
      value: 'PR',
      text: 'Puerto Rico(PR)'
    },
    {
      value: 'RI',
      text: 'Rhode Island(RI)'
    },
    {
      value: 'SC',
      text: 'South Carolina(SC)'
    },
    {
      value: 'SD',
      text: 'South Dakota(SD)'
    },
    {
      value: 'TN',
      text: 'Tennessee(TN)'
    },
    {
      value: 'TX',
      text: 'Texas(TX)'
    },
    {
      value: 'UT',
      text: 'Utah(UT)'
    },
    {
      value: 'VA',
      text: 'Virginia(VA)'
    },
    {
      value: 'VI',
      text: 'Virgin Islands(VI)'
    },
    {
      value: 'VT',
      text: 'Vermont(VT)'
    },
    {
      value: 'WA',
      text: 'Washington(WA)'
    },
    {
      value: 'WI',
      text: 'Wisconsin(WI)'
    },
    {
      value: 'WV',
      text: 'West Virginia(WV)'
    },
    {
      value: 'WY',
      text: 'Wyoming(WY)'
    },
    {
      value: 'AE',
      text: 'Armed Forces Europe(AE)'
    },
    {
      value: 'AP',
      text: 'Armed Forces Pacific(AP)'
    },
    {
      value: 'AA',
      text: 'Armed Forces Canada(AA)'
    }
  ],
  DUTY_RATES: {
    apparel: {
      en: 'Apparel',
      fr: 'Vêtements',
      items: {
        accessories: {
          en: 'Accessories (hats, sunglasses)',
          fr: 'Accessoires (chapeaux, lunettes de soleil)',
          mfn: '0.155',
          cusma: '0'
        },
        boys: {
          en: "Boys' clothing (bottoms, shirts, tops, outerwear, sleepwear)",
          fr: "Vêtements pour garçons (bas, chemises, hauts, vêtements d'extérieur, vêtements de nuit)",
          mfn: '0.18',
          cusma: '0'
        },
        girls: {
          en: "Girls' clothing (bottoms, shirts, tops, outerwear, sleepwear)",
          fr: "Vêtements pour fillettes (bas, chemises, hauts, vêtements d'extérieur, vêtements de nuit)",
          mfn: '0.18',
          cusma: '0'
        },
        gloves: {
          en: 'Gloves',
          fr: 'Gants',
          mfn: '0.18',
          cusma: '0'
        },
        ladies: {
          en: "Ladies' clothing (pants, tops, dresses, socks, underwear, sleepwear)",
          fr: 'Vêtements pour femmes (pantalons, hauts, robes, chaussettes, sous-vêtements, vêtements de nuit)',
          mfn: '0.18',
          cusma: '0'
        },
        luggage: {
          en: 'Luggage (backpacks, suitcases, carry-ons)',
          fr: 'Bagagerie (sacs à dos, valises, bagages à main)',
          mfn: '0.11',
          cusma: '0'
        },
        mens: {
          en: "Mens' clothing (pants, jeans, shirts, t-shirts, briefs, socks, sleepwear)",
          fr: 'Vêtements pour hommes (pantalons, jeans, chemises, tee-shirts, caleçons, chaussettes, vêtements de nuit)',
          mfn: '0.18',
          cusma: '0'
        },
        shoes: {
          en: 'Shoes (athletic, casual boots, sandals, slippers, skates)',
          fr: 'Chaussures de sport (bottes, sandales, pantoufles, patins)',
          mfn: '0.20',
          cusma: '0'
        }
      }
    },
    appliances: {
      en: 'Appliances',
      fr: 'Appareils électroménagers',
      items: {
        kitchen: {
          en: 'Kitchen appliances (blender, bread maker, coffee maker, food processor, toaster, kettle, slow cooker)',
          fr: 'Appareils de cuisine (mixeur, machine à pain, cafetière, robot culinaire, grille-pain, bouilloire, mijoteuse)',
          mfn: '0.09',
          cusma: '0'
        },
        large: {
          en: 'Large appliances (washer, dryer, refrigerator, dishwasher)',
          fr: 'Gros appareils ménagers (laveuse, sécheuse, réfrigérateur, lave-vaisselle)',
          mfn: '0.08',
          cusma: '0'
        },
        vacuums: {
          en: 'Vacuums',
          fr: 'Aspirateurs',
          mfn: '0.08',
          cusma: '0'
        }
      }
    },
    automotive: {
      en: 'Automotive',
      fr: 'Automobile',
      items: {
        engines: {
          en: 'Automobile engines',
          fr: "Moteurs d'automobile",
          mfn: '0',
          cusma: '0'
        },
        parts: {
          en: 'Automotive parts',
          fr: 'Pièces pour véhicules automobiles',
          mfn: '0.085',
          cusma: '0'
        },
        brakes: {
          en: 'Brakes and brake linings',
          fr: 'Freins et garnitures de frein',
          mfn: '0.10',
          cusma: '0'
        },
        tires: {
          en: 'Tires',
          fr: 'Pneus',
          mfn: '0.07',
          cusma: '0'
        },
        wipers: {
          en: 'Wipers',
          fr: 'Essuie-glaces',
          mfn: '0',
          cusma: '0'
        }
      }
    },
    babyItems: {
      en: 'Baby Items',
      fr: 'Articles pour bébés',
      items: {
        bottles: {
          en: 'Baby bottles, nipples, pacifiers',
          fr: 'Biberons, tétines, sucettes',
          mfn: '0.065',
          cusma: '0'
        },
        cereal: {
          en: 'Baby cereal',
          fr: 'Céréales pour bébés',
          mfn: '0.095',
          cusma: '0'
        },
        clothing: {
          en: 'Baby clothing  (creeper set, tops, tunics, pants)',
          fr: 'Vêtements pour bébés (barbotteuses, hauts, pantalons, tuniques)',
          mfn: '0',
          cusma: '0'
        },
        monitors: {
          en: 'Baby monitors',
          fr: 'Moniteurs pour bébés',
          mfn: '0',
          cusma: '0'
        },
        carSeats: {
          en: 'Car seats',
          fr: "Sièges d'auto",
          mfn: '0.06',
          cusma: '0'
        },
        carriage: {
          en: 'Carriage/Stroller',
          fr: 'Transport / Poussette',
          mfn: '0.08',
          cusma: '0'
        },
        crib: {
          en: 'Crib',
          fr: "Lit d'enfant",
          mfn: '0.095',
          cusma: '0'
        },
        diapers: {
          en: 'Diapers',
          fr: 'Couches',
          mfn: '0.18',
          cusma: '0'
        },
        formula: {
          en: 'Formula',
          fr: 'Formules',
          mfn: '0.095',
          cusma: '0'
        },
        highChairs: {
          en: 'High chairs',
          fr: 'Chaises hautes',
          mfn: '0.095',
          cusma: '0'
        }
      }
    },
    electronics: {
      en: 'Electronics & Media',
      fr: 'Matériel électronique et médias',
      items: {
        batteries: {
          en: 'Batteries',
          fr: 'Piles',
          mfn: '0.07',
          cusma: '0'
        },
        cameras: {
          en: 'Cameras',
          fr: 'Caméras',
          mfn: '0.05',
          cusma: '0'
        },
        computers: {
          en: 'Computers',
          fr: 'Informatique',
          mfn: '0',
          cusma: '0'
        },
        software: {
          en: 'Computer software',
          fr: 'Logiciels',
          mfn: '0',
          cusma: '0'
        },
        dvdPlayers: {
          en: 'DVD / Blu-ray players',
          fr: 'DVD / Blu-ray',
          mfn: '0',
          cusma: '0'
        },
        phones: {
          en: 'Phones',
          fr: 'Téléphones',
          mfn: '0',
          cusma: '0'
        },
        dvds: {
          en: 'Pre-recorded DVDs, CDs, VHS tapes',
          fr: 'DVD préenregistrés, disques compacts, cassettes VHS',
          mfn: '0',
          cusma: '0'
        },
        printers: {
          en: 'Printers',
          fr: 'Imprimantes',
          mfn: '0',
          cusma: '0'
        },
        tablets: {
          en: 'Tablets, e-readers',
          fr: 'Tablettes, lecteurs électroniques',
          mfn: '0',
          cusma: '0'
        },
        televisions: {
          en: 'Televisions',
          fr: 'Téléviseurs',
          mfn: '0.05',
          cusma: '0'
        },
        console: {
          en: 'Video console',
          fr: 'Consoles vidéo',
          mfn: '0',
          cusma: '0'
        },
        videoGames: {
          en: 'Video games',
          fr: 'Jeux vidéo',
          mfn: '0',
          cusma: '0'
        }
      }
    },
    furnishings: {
      en: 'Home Furnishings',
      fr: "Fournitures et accessoires d'ameublement de maison",
      items: {
        bathroom: {
          en: 'Bathroom: Towels, shower curtains',
          fr: 'Salle de bains : serviettes, rideaux de douche',
          mfn: '0.18',
          cusma: '0'
        },
        bedroom: {
          en: 'Bedroom: Duvets, pillows, sheets, blankets',
          fr: 'Chambre à coucher : couettes, oreillers, draps, couvertures',
          mfn: '0.18',
          cusma: '0'
        },
        furniture: {
          en: 'Furniture: Bedroom, home entertainment, living room, desk, dining room',
          fr: 'Meubles : chambre à coucher, divertissement, salon, bureau, salle à manger',
          mfn: '0.095',
          cusma: '0'
        },
        kitchen: {
          en: 'Kitchen: Cookware, dishes, utensils',
          fr: 'Cuisine : batterie de cuisine, vaisselle, ustensiles',
          mfn: '0.07',
          cusma: '0'
        }
      }
    },
    food: {
      en: 'Food',
      fr: 'Alimentation',
      items: {
        bread: {
          en: 'Bread',
          fr: 'Pain',
          mfn: '0.085',
          cusma: '0',
          perKilo: '0.0847'
        },
        cereal: {
          en: 'Cereal',
          fr: 'Céréale',
          mfn: '0.04',
          cusma: '0'
        },
        dairy: {
          en: 'Dairy: Cheese, milk, ice cream, yogurt',
          fr: 'Produits laitiers : Fromage, lait, crème glacée, yogourt',
          mfn: '3.135',
          cusma: '3.135',
          perKiloMin: '5.12'
        },
        fruit: {
          en: 'Fruit',
          fr: 'Fruits',
          mfn: '0.125',
          cusma: '0'
        },
        poultry: {
          en: 'Meat: Poultry',
          fr: 'Viande : volaille',
          mfn: '2.49',
          cusma: '2.49',
          perKiloMin: '6.74'
        },
        beef: {
          en: 'Meat: Beef',
          fr: 'Viande : bœuf',
          mfn: '0.265',
          cusma: '0'
        },
        pork: {
          en: 'Meat: Pork',
          fr: 'Viande : porc',
          mfn: '0',
          cusma: '0'
        },
        prepared: {
          en: 'Prepared meals',
          fr: 'Repas préparés',
          mfn: '2.53',
          cusma: '2.53',
          perKiloMin: '10.54'
        },
        vegetables: {
          en: 'Vegetables',
          fr: 'Légumes',
          mfn: '0.105',
          cusma: '0'
        }
      }
    },
    health: {
      en: 'Health & Wellness',
      fr: 'Santé et mieux-être',
      items: {
        beauty: {
          en: 'Beauty or makeup preparations',
          fr: 'Produits de beauté ou de maquillage',
          mfn: '0.065',
          cusma: '0'
        },
        shampoo: {
          en: 'Shampoo',
          fr: 'Shampooing',
          mfn: '0.065',
          cusma: '0'
        },
        soap: {
          en: 'Soap',
          fr: 'Savon',
          mfn: '0.065',
          cusma: '0'
        },
        vitamins: {
          en: 'Vitamins',
          fr: 'Vitamines',
          mfn: '0.105',
          cusma: '0'
        }
      }
    },
    jewellery: {
      en: 'Jewellery',
      fr: 'Bijoux',
      items: {
        costume: {
          en: 'Costume jewellery',
          fr: 'Bijouterie de fantaisie',
          mfn: '0.085',
          cusma: '0'
        },
        precious: {
          en: 'Precious and semi precious',
          fr: 'Pierres précieuses et semi-précieuses',
          mfn: '0.085',
          cusma: '0'
        },
        watches: {
          en: 'Watches',
          fr: 'Montres',
          mfn: '0.05',
          cusma: '0'
        }
      }
    },
    media: {
      en: 'Movies, Music, Books',
      fr: 'Films, musique, livres',
      items: {
        books: {
          en: 'Books',
          fr: 'Livres',
          mfn: '0',
          cusma: '0'
        },
        movies: {
          en: 'Movies, TV programs (DVDs)',
          fr: 'Films, programmes télévisés (DVD)',
          mfn: '0',
          cusma: '0'
        },
        music: {
          en: 'Music (CDs)',
          fr: 'Musique (CD)',
          mfn: '0',
          cusma: '0'
        }
      }
    },
    outdoor: {
      en: 'Outdoor Living',
      fr: 'Meubles de jardin',
      items: {
        bbq: {
          en: 'BBQ',
          fr: 'BBQ',
          mfn: '0.08',
          cusma: '0'
        },
        gazebos: {
          en: 'Gazebos',
          fr: 'Kiosques de jardin',
          mfn: '0.065',
          cusma: '0'
        },
        sheds: {
          en: 'Garden sheds & storage',
          fr: 'Remises de jardin',
          mfn: '0.065',
          cusma: '0'
        },
        handTools: {
          en: 'Garden handheld tools',
          fr: 'Petits outils pour jardinage',
          mfn: '0.11',
          cusma: '0'
        },
        patio: {
          en: 'Patio sets',
          fr: 'Ensembles de patio',
          mfn: '0.095',
          cusma: '0'
        },
        powerTools: {
          en: 'Power tools',
          fr: 'Outils électriques',
          mfn: '0',
          cusma: '0'
        }
      }
    },
    sports: {
      en: 'Sports',
      fr: 'Sport',
      items: {
        bicycles: {
          en: 'Bicycles',
          fr: 'Vélos',
          mfn: '0.13',
          cusma: '0'
        },
        camping: {
          en: 'Camping (tents, sleeping bags)',
          fr: 'Camping (tentes, sacs de couchage)',
          mfn: '0.18',
          cusma: '0'
        },
        canoes: {
          en: 'Canoes',
          fr: 'Canots',
          mfn: '0.095',
          cusma: '0'
        },
        exercise: {
          en: 'Exercise equipment (treadmills, exercise bikes, home gym)',
          fr: "Matériel d'exercice (tapis roulants, vélos d'exercice, gymnase personnel)",
          mfn: '0',
          cusma: '0'
        },
        golf: {
          en: 'Golf clubs',
          fr: 'Bâtons de golf',
          mfn: '0',
          cusma: '0'
        },
        hockey: {
          en: 'Hockey stick',
          fr: 'Bâtons de hockey',
          mfn: '0',
          cusma: '0'
        },
        jetSki: {
          en: 'Personal watercraft (jet ski)',
          fr: 'Motomarines (jet-ski)',
          mfn: '0.095',
          cusma: '0'
        },
        safety: {
          en: 'Safety helmets, pumps, seat pad',
          fr: 'Casques de sécurité, pompes, coussin de siège',
          mfn: '0',
          cusma: '0'
        }
      }
    },
    toys: {
      en: 'Toys',
      fr: 'Jouets',
      items: {
        dolls: {
          en: 'Action figures, animals & stuffed toys, dolls',
          fr: 'Figurines, animaux et jouets en peluche, poupées',
          mfn: '0',
          cusma: '0'
        },
        games: {
          en: 'Board games, puzzles',
          fr: 'Jeux de société, casse-tête',
          mfn: '0',
          cusma: '0'
        },
        vehicle: {
          en: 'Vehicle play sets',
          fr: 'Ensembles de petites voitures',
          mfn: '0',
          cusma: '0'
        },
        wheeledToys: {
          en: 'Wheeled toys (tricycles & pedal cars)',
          fr: 'Jouets à roues (tricycles et voitures à pédales)',
          mfn: '0.08',
          cusma: '0'
        }
      }
    },
    other: {
      en: 'Other',
      fr: 'Jouets',
      items: {
        other: {
          en: 'Other',
          fr: 'Figurines, animaux et jouets en peluche, poupées',
          mfn: '0.18',
          cusma: '0.18'
        }
      }
    }
  },
  CARRIERS: [
    'USPS',
    'FedEx',
    'UPS',
    'Amazon',
    'Walmart',
    'Freight',
    'LS',
    'DHL',
    'DOORDASH',
    'TARGET',
    'INSTACART'
  ],
  SHIPMENT_TYPES: [
    {
      text: 'Shipment',
      value: 1
    },
    {
      text: 'Pallet',
      value: 2
    },
    {
      text: 'Letter Mail',
      value: 3
    }
  ],
  SHIPMENT_STATUSES: {
    PENDING_DETAILS: 1,
    IN_CANADA: 30
  },
  SHIPMENT_SERVICES: {
    ACTION_REQUIRED: 1,
    ITEM_REQUESTED: 2
  },
  USER_ACCOUNT_TYPES: {
    SHIPPSY: 'Shippsy',
    PUDO: 'PUDO'
  }
};
